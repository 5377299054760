import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getImageFromIpfs } from '../utils/Metamask'
import useIsMounted from 'react-is-mounted-hook'
import NFTImg from '../public/assets/content/card-pic-default.png'

const imgStyle = {
  objectFit: 'cover',
  minWidth: '100%',
  minHeight: '100%',
}

const LazyImage = ({ lazySrc, src, alt, width, height, style, ...props }) => {
  const isMounted = useIsMounted()
  const [loading, setLoading] = useState(true)
  const [loadingPlaceholder, setLoadingPlaceholder] = useState(true)
  const [ipfsdata, setIpfsdata] = useState('')

  const handleOnLoadPlaceholder = () => {
    setLoadingPlaceholder(false)
  }

  const handleOnLoad = () => {
    setLoading(false)
  }

  const handleOnError = (e) => {
    setLoading(true)
  }

  const getIpfsdata = useCallback(async () => {
    try {
      if (src.slice(0, 7) === 'ipfs://') {
        const data = await getImageFromIpfs(src.slice(7))
        if (!isMounted()) return
        setIpfsdata(data)
        setLoading(false)
      } else {
        return
      }
    } catch (err) {
      console.error(err)
    }
  }, [src, isMounted])

  useEffect(() => {
    getIpfsdata()
  }, [getIpfsdata])

  return (
    <>
      {((src.slice(0, 7) === 'ipfs://' && !ipfsdata) || loading) && (
        <img
          src={lazySrc}
          alt={alt}
          style={{
            ...imgStyle,
            ...style,
            display: loadingPlaceholder ? 'none' : 'inline-block',
          }}
          onLoad={handleOnLoadPlaceholder}
          {...props}
        />
      )}
      {src.slice(0, 7) === 'ipfs://' ? (
        <img
          src={ipfsdata}
          alt={alt}
          onError={handleOnError}
          style={{
            ...imgStyle,
            display: loading ? 'none' : 'inline-block',
            ...style,
          }}
          {...props}
        />
      ) : (
        <img
          src={src}
          alt={alt}
          onLoad={handleOnLoad}
          onError={handleOnError}
          style={{
            ...imgStyle,
            display: loading ? 'none' : 'inline-block',
            ...style,
          }}
          {...props}
        />
      )}
    </>
  )
}

LazyImage.defaultProps = {
  lazySrc: NFTImg.src,
  src: '',
  alt: 'Image',
  style: {},
}

LazyImage.propTypes = {
  lazySrc: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
}

export default LazyImage
