import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@equinox-fund/equinox-lib'

import Loader from '../components/Loader'

const StatusLoader = ({ status, loading, style, ...props }) => {
  return (
    <div className="flex justify-end mt-8">
      <div className="upload__saving">
        <Typography color="muted" size="small" className="mr-3">{status}</Typography>
        {loading && <Loader style={{ display: 'block', margin: 12, ...style }} {...props} />}
      </div>
    </div>
  )
}

StatusLoader.defaultProps = {
  status: '',
  loading: true,
  style: {},
}

StatusLoader.propTypes = {
  status: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
}

export default StatusLoader
