import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// const containerStyle = {
//   textAlign: 'center',
//   position: 'absolute',
//   width: '100%',
//   height: '100%',
//   left: 0,
//   top: 0,
//   padding: '0 8px',
//   boxSizing: 'border-box',
// }

const contentStyle = {
  cursor: 'auto',
  textAlign: 'left',
  zIndex: 1045,
  transition: 'all 0.2s ease-in-out',
  opacity: 1,
  width: '100%',
  margin: '0 auto'
}

const contentClosing = {
  transform: 'scale(0.5)',
  opacity: 0,
}

const contentClosed = {
  transform: 'scale(0.0)',
  pointerEvents: 'none',
  opacity: 0,
}

const overlayStyle = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  zIndex: 100,
  transition: 'all 0.2s ease-in-out',
  overflowY: 'scroll',
  padding: '32px 16px'
}

const overlayClosing = {
  opacity: 0,
}

const overlayClosed = {
  opacity: 0,
  pointerEvents: 'none',
}

const GenericPopup = ({ active, onClickOff, children }) => {
  const [showContent, setShowContent] = useState(false)

  const handleClickBody = (e) => {
    e.stopPropagation()
  }

  const handleClickOff = (e) => {
    e.preventDefault()
    onClickOff(e)
  }

  useEffect(() => {
    if (active) {
      setShowContent(true)
      document.body.style.height = '100vh'
      document.body.style.overflowY = 'hidden'
      document.body.style.paddingRight = '5px'
      return
    }
    document.body.style.height = ''
    document.body.style.overflowY = ''
    document.body.style.paddingRight = ''
    const timeout = setTimeout(() => {
      setShowContent(false)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [active])

  return (
    <div
      style={{ ...overlayStyle, ...(active ? {} : showContent ? overlayClosing : overlayClosed) }}
      onMouseDown={handleClickOff}
    >
        {showContent && (
        <div className="popup" onMouseDown={handleClickBody} style={{
          ...contentStyle,
          ...(active ? {} : showContent ? contentClosing : contentClosed),
        }}>
            {children}
          </div>
        )}
    </div>
  )
}

GenericPopup.defaultProps = {
  active: false,
  onClickOff: () => {},
  children: <></>,
}

GenericPopup.propTypes = {
  active: PropTypes.bool,
  onClickOff: PropTypes.func,
  children: PropTypes.node,
}

export default GenericPopup
