import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@equinox-fund/equinox-lib'

const Alert = ({ color, onClose, children, style, ...props }) => {
  return (
    <div
      className="alert"
      style={{
        width: 'auto',
        height: 'auto',
        backgroundColor: color,
        ...style,
      }}
      {...props}
    >
      <span className="closebtn" onClick={onClose}>
        &times;
      </span>
      <Typography size="large" caption>{children}</Typography>
    </div>
  )
}

Alert.defaultProps = {
  color: '#ff4242',
  onClose: () => {},
  children: <>Error</>,
  style: {},
}

Alert.propTypes = {
  color: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Alert
