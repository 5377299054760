import { Title, Typography } from '@equinox-fund/equinox-lib'

const EquinoxTokenTable = () => (
  <>
    <div className="nft__table">
      <div className="row heading">
        <div className="column key"></div>
        <div className="values">
          <div className="column">
            <Title>Autumnal</Title>
          </div>
          <div className="column">
            <Title>Winter</Title>
          </div>
          <div className="column">
            <Title>Vernal</Title>
          </div>
          <div className="column">
            <Title>Summer</Title>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column key">
          <Title>IDO</Title>
        </div>
        <div className="values">
          <div className="column">
            <Typography color="muted">Lottery/FCFS</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Lottery/FCFS</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Lottery/FCFS</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Guaranteed allocation</Typography>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="column key">
          <Title>INO</Title>
        </div>
        <div className="values">
          <div className="column">
            <Typography color="muted">Lottery</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Lottery</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Lottery</Typography>
          </div>
          <div className="column">
            <Typography color="muted">Lottery</Typography>
          </div>
        </div>
      </div>
    </div>
    <Typography color="muted" size="small" caption>
      Guranteed allocation: Your allocation is guaranteed. <br />
      Lottery/FCFS: If you win, you will get a guaranteed allocation in the winner&apos;s pool. If
      you don’t win the lottery, you will get access to the First Come, First Served pool (usually
      called the FCFS Pool). Be quick!
      <br />
      FCFS (First Come, First Served): You need to be quick in order to guarantee your allocation.
      This system is usually used when we offer unsold tokens to our NFT holders, or if you were not
      successful in the lottery.
      <br />
      Lottery: If you win, you will get a guaranteed allocation.
    </Typography>
  </>
)

export default EquinoxTokenTable
