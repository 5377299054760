import React from 'react'
import PropTypes from 'prop-types'
import GenericPopup from './GenericPopup'
import { Title, Typography, Button, Divider } from '@equinox-fund/equinox-lib'

import StatusLoader from '../StatusLoader'
import Alert from '../Alert'
import NftPreview from './NftPreview'

const signatureDeniedError = 'MetaMask Tx Signature: User denied transaction signature.'

const PurchasePopup = ({
  name,
  price,
  description,
  lowestPrice,
  available,
  image,
  active,
  loading,
  status,
  error,
  onClickOff,
  onClickPurchase,
  onCloseError,
}) => {
  const handleClickPurchase = (e) => {
    e.preventDefault()
    onClickPurchase(e)
  }

  return (
    <GenericPopup active={active} onClickOff={onClickOff}>
      <div>
        <div className="mb-10">
          <Title size="large" className="mb-2">Purchase NFT</Title>
        </div>
        <div className="mb-8">
          {error && (
            <div className="w-full mb-3">
              <Alert onClose={onCloseError}>
                {error === signatureDeniedError ? 'Purchase Denied' : error}
              </Alert>
            </div>
          )}
          <NftPreview name={name} image={image} />
        </div>
        <div>
          <Title size="large">{price} BNB</Title>
          {!!lowestPrice && (
            <Typography color="muted">
              Lowest price ${lowestPrice}
              <div className="xs:inline-block">
                <span className="mx-1 hidden xs:inline-block">{' • '}</span>
                <span>{available} available</span>
              </div>
            </Typography>
          )}
          <Divider className="my-6 md:my-8" horizontal />
          <div className="flex items-center justify-end">
            <Button
              variant="text"
              size="large"
              color="secondary"
              disabled={loading}
              onClick={onClickOff}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              disabled={loading}
              onClick={handleClickPurchase}
            >
              Buy Now
            </Button>
          </div>
        </div>
        {status && <StatusLoader status={status} />}
      </div>
    </GenericPopup>
  )
}

PurchasePopup.defaultProps = {
  name: '',
  description: '',
  price: 0,
  lowestPrice: 0,
  available: 0,
  image: '',
  active: false,
  loading: false,
  status: '',
  error: '',
  onClickOff: () => {},
  onClickPurchase: () => {},
  onCloseError: () => {},
}

PurchasePopup.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number,
  lowestPrice: PropTypes.number,
  available: PropTypes.number,
  image: PropTypes.string,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  status: PropTypes.string,
  error: PropTypes.string,
  onClickOff: PropTypes.func,
  onClickPurchase: PropTypes.func,
  onCloseError: PropTypes.func,
}

export default PurchasePopup
