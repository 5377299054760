import PropTypes from 'prop-types'
import { Title, Typography } from '@equinox-fund/equinox-lib'
import SmartImage from '../SmartImage'


const NftPreview = ({ name, description, image }) => (
  <div className="card__condensed">
    <div className="card__condensed__preview">
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
      >
        <SmartImage src={image} alt="Card preview" />
      </div>
    </div>
    <div className="mx-4 mb-2 xs:mb-0">
      <Title>{name}</Title>
      {description && <Typography size="small" className="mt-1">{description}</Typography>}
    </div>
  </div>
)

NftPreview.defaultProps = {
  name: '',
  description: '',
  image: ''
}

NftPreview.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string
}

export default NftPreview
