import { Divider, Heading, Title, Typography } from '@equinox-fund/equinox-lib'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState, useContext } from 'react'
import toast from 'react-hot-toast'
import useIsMounted from 'react-is-mounted-hook'
import Slider from 'react-slick'
import CoreNftCard from '../components/CoreNftCard'
import EquinoxTokenTable from '../components/EquinoxTokenTable'
import LazyImage from '../components/LazyImage'
import PurchasePopup from '../components/popups/PurchasePopup'
import SmartImage from '../components/SmartImage'
import { getBnbPrice } from '../utils/Api'
import { acceptNftOffer, getMetamaskAccount } from '../utils/Metamask'
import { getCoreNFT, fetchCoreNftsQuick, getRandomImagesByCategory, getUserOffers } from '../utils/Nfts'
import { UserContext } from '../components/UserContext'
import { NftContext } from '../components/NftContext'
import { useQueryClient } from 'react-query'
import { ethers } from 'ethers'



// copied from https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
  <span {...props}>{children}</span>
)
const prevArrow = (
  <SlickButtonFix>
    <button type="button" className="slick-prev">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" fill="none" viewBox="0 0 14 9">
        <path
          fillRule="evenodd"
          d="M4.909.265a1 1 0 0 0-1.413.057l-3.231 3.5a1 1 0 0 0 0 1.357l3.231 3.5a1 1 0 0 0 1.47-1.357L3.284 5.5H13a1 1 0 1 0 0-2H3.284l1.682-1.822A1 1 0 0 0 4.909.265z"
          fill="#777e91"
        />
      </svg>
    </button>
  </SlickButtonFix>
)
const nextArrow = (
  <SlickButtonFix>
    <button type="button" className="slick-next">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" fill="none" viewBox="0 0 14 9">
        <path
          fillRule="evenodd"
          d="M9.091.265a1 1 0 0 1 1.413.057l3.231 3.5a1 1 0 0 1 0 1.357l-3.231 3.5a1 1 0 0 1-1.47-1.357L10.716 5.5H1a1 1 0 1 1 0-2h9.716L9.034 1.678A1 1 0 0 1 9.091.265z"
          fill="#23262f"
        />
      </svg>
    </button>
  </SlickButtonFix>
)

export const collectionsSettings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: prevArrow,
  nextArrow: nextArrow,
  dots: false,
  speed: 500,
  infinite: false,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const coreNames = ['Autumnal', 'Winter', 'Vernal', 'Summer']
const emptyCoreNfts = {
      Autumnal: {},
      Vernal: {},
      Winter: {},
      Summer: {},
    }

const Home = () => {
  const isMounted = useIsMounted()
  const router = useRouter()
  const queryClient = useQueryClient()
  const user = useContext(UserContext)
  const {nfts: allNfts} = useContext(NftContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [activeNft, setActiveNft] = useState({})
  const [purchasePopup, setPurchasePopup] = useState(false)
  const [status, setStatus] = useState('')
  const [purchaseLoading, setPurchaseLoading] = useState(false)
  const [bnbPrice, setBnbPrice] = useState(0)

  const [coreNfts, setCoreNfts] = useState(emptyCoreNfts)

  const handleCloseError = () => {
    setError('')
  }

  const handleClickNFT = (nft) => {
    router.push(`/item/${nft.id}`)
    window.scrollTo(0, 0)
  }

  const handleClickNFTPendingPurchase = (name) => {
    setActiveNft(coreNfts[name])
    setPurchasePopup(true)
  }

  const handleClickOffPurchase = () => {
    if (purchaseLoading) return
    setPurchasePopup(false)
  }

  const handleClickNFTPurchase = useCallback(async () => {
    try {
      setPurchaseLoading(true)
      setError('')
      if (!user.address) throw Error('You must have a connected wallet to purchase an NFT')
      if (activeNft.status !== 'TRADEABLE') throw Error('You cannot trade this token')

      const offers = await getUserOffers(activeNft.id, activeNft.minters[0])
      if (offers.length === 0) Error('NFT is out of stock')
      const offer = offers[0]

      if (offer.price > activeNft.minterPrice)
        throw Error('Price increased in the meantime. Please reload the page')
      if (offer.price >= user.balance)
        throw Error('You do not have sufficient funds to purchase this NFT')
      setStatus('Initiating Transaction')
      window.onbeforeunload = () => {
        return ''
      }
      await acceptNftOffer({ ...offer, amount: 1 })
      setStatus('Finalizing Buying')

      router.push(`/profile/${user.address}`)
      window.scrollTo(0, 0)
      toast.success(
        (t) => (
          <span>
            Successfully purchased the <b>{activeNft.name}</b> NFT
          </span>
        ),
        {
          duration: 10000,
          style: {
            borderRadius: '8px',
            background: '#333',
            color: '#fff',
          },
        }
      )
    } catch (err) {
      console.error(err)
      setError(err?.response?.data?.msg || err.message)
    } finally {
      if (!isMounted()) return
      setPurchaseLoading(false)
      setStatus('')
      window.onbeforeunload = undefined
    }
  }, [activeNft, user, router, isMounted])

  const populateLoading = useCallback(async () => {
    try {
      if (!isMounted()) return
      let core = {}
      if (coreNfts.Autumnal?.name === "Autumnal") {
        core = coreNfts
      } else if (allNfts?.version > 0) {
        setLoading(true)
        for (let name of coreNames) {
          core[name] = getCoreNFT(allNfts.nftData, name)
        }
        setCoreNfts(core)
        setLoading(false)
      } else {
        setLoading(true)
        core = await fetchCoreNftsQuick(coreNames, queryClient)
        setCoreNfts(core)
        setLoading(false)
      }
            
      let promises = []
      for (let name of coreNames) {
        let prom = queryClient.fetchQuery("core-minter-offers-"+name, () => getUserOffers(core[name].id, core[name].minters[0]))
        promises.push(prom)
      }
      const offers = await Promise.all(promises)
      for (let i=0; i<coreNames.length; i++) {
        let name = coreNames[i]
        let price = offers[i].length > 0 ? offers[i][0].price : -1
        let bnPrice = offers[i].length > 0 ? offers[i][0].bnPrice : ethers.BigNumber.from('-1')
        core[name].minterPrice = price
        core[name].minterBnPrice = bnPrice
      }
      setCoreNfts(core)
      setLoading(false)

    } catch (err) {
      console.error(err)
      setError(err?.response?.data?.msg || err.message)
      setLoading(false)
    }
  }, [isMounted, queryClient, allNfts])

  useEffect(() => {
    populateLoading()
  }, [populateLoading, allNfts])

  const populateBnbPrice = useCallback(async () => {
    try {
      const price = await getBnbPrice()
      if (!isMounted()) return

      setBnbPrice(price)
    } catch (err) {
      console.error(err)
    }
  }, [isMounted])

  useEffect(() => {
    populateBnbPrice()
  }, [populateBnbPrice])

  return (
    <div className="outer__inner">
      <Head>
        <title>Equinox Marketplace</title>
        <meta
          name="description"
          content="A fair and decentralized NFT marketplace. Buy, rent and lend NFTs to access the Equinox Ecosystem."
        />
        <meta name="og:title" content="Equinox Marketplace" />
        <meta
          name="og:description"
          content="A fair and decentralized NFT marketplace. Buy, rent and lend NFTs to access the Equinox Ecosystem."
        />
      </Head>
      <PurchasePopup
        name={activeNft.name}
        description={activeNft.description}
        price={activeNft.minterPrice}
        lowestPrice={Math.round(activeNft.minterPrice * bnbPrice * 100) / 100}
        available={activeNft.marketplaceSupply}
        priceType={activeNft.priceType}
        image={activeNft.image}
        active={purchasePopup}
        status={status}
        loading={purchaseLoading}
        error={error}
        onClickPurchase={handleClickNFTPurchase}
        onClickOff={handleClickOffPurchase}
        onCloseError={handleCloseError}
      />
      <div className="section main">
        <div className="main__center center mb-4">
          <div className="main__head">
            <Heading className="mb-4 md:mb-8">Buy Your NFT</Heading>
            <Typography size="large">Get exclusive access to the launchpad</Typography>
          </div>
          <div className="corenft__list">
            {coreNames.map((name, index) => (
              <div key={`corenft-${index}`}>
                <CoreNftCard
                  name={coreNfts[name].name}
                  imageEl={<SmartImage src={coreNfts[name].image} alt="Card preview" />}
                  price={coreNfts[name].minterPrice}
                  usdPrice={Math.round(coreNfts[name].minterPrice * bnbPrice * 100) / 100}
                  loading={loading}
                  buttonDisabled={coreNfts[name].minterPrice <= 0}
                  onClickPurchase={() => handleClickNFTPendingPurchase(name)}
                />
              </div>
            ))}
          </div>
          <EquinoxTokenTable />
        </div>
      </div>

      {!loading && (
        <>
          <Divider horizontal />
          <div className="section collections">
            <div className="collections__center center">
              <div className="collections__wrapper">
                <Heading size="small" className="mb-8 md:mb-10">
                  Collections
                </Heading>
                <div className="collections__inner">
                  <Slider
                    className="collections__slider js-slider-collections"
                    {...collectionsSettings}
                  >
                    {allNfts?.categories &&
                      allNfts.categories.map((cat, index) => (
                        <div
                          className="collections__item"
                          onClick={(e) => {
                            e.preventDefault()
                            router.push({
                              pathname: '/marketplace',
                              state: {
                                passedCategory: cat,
                              },
                            })
                          }}
                          key={`category-item-${index}`}
                        >
                          <div
                            className="collections__gallery"
                            key={`category-galery-item-${index}`}
                          >
                            {getRandomImagesByCategory(allNfts, cat, 4).map((img, imgIndex) => (
                              <div
                                className="collections__preview"
                                key={`ctegory-prev-${index}-${imgIndex}`}
                              >
                                <LazyImage
                                  src={img}
                                  alt="Collections"
                                  key={`ctegory-img-${index}-${imgIndex}`}
                                />
                              </div>
                            ))}
                          </div>
                          <Title>{cat}</Title>
                        </div>
                      ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Home
