import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import useIsMounted from 'react-is-mounted-hook'
import Image from 'next/image'
import { getImageFromIpfs } from '../utils/Metamask'

const SmartImage = ({ src, style, alt, layout, ...props }) => {
  const isMounted = useIsMounted()
  const [ipfsdata, setIpfsdata] = useState('')

  const getIpfsdata = useCallback(async () => {
    try {
      if (src.slice(0, 7) === 'ipfs://') {
        const data = await getImageFromIpfs(src.slice(7))
        if (!isMounted()) return
        setIpfsdata(data)
      }
    } catch (err) {
      console.error(err)
    }
  }, [src, isMounted])

  useEffect(() => {
    getIpfsdata()
  }, [getIpfsdata])

  const placeholderImg = <div className="animate-pulse bg-neutral-ash w-full h-full absolute top-0 left-0" />

  const getImage = () => {
    if (!src) {
      return placeholderImg
    }
    if (src.slice(0, 7) === 'ipfs://') {
      // Is ipfs
      if (ipfsdata) {
        return (
          <Image
            src={ipfsdata}
            placeholder="blur"
            blurDataURL={ipfsdata}
            alt={alt}
            layout={layout}
            draggable={false}
            {...props}
          />
        )
      } else {
        return placeholderImg
      }
    }
    return (
      <Image
        src={src}
        placeholder="blur"
        blurDataURL={src}
        alt={alt}
        layout={layout}
        draggable={false}
        {...props}
      />
    )
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        overflow: 'auto',
        maxWidth: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          paddingBottom: '100%',
        }}
      >
        {getImage()}
      </div>
    </div>
  )
}

SmartImage.defaultProps = {
  src: '',
  style: {},
  alt: '',
  layout: 'fill',
}

SmartImage.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  layout: PropTypes.string,
}

export default SmartImage
